import React from "react"
import styled from "styled-components"

import { ContainerPage, WrapperPage } from "src/componentsV2/ui/layout"
import Introduce from "./Introduce"

const EverythingYouNeedContaner = styled(ContainerPage)`
  padding-bottom: 0px;
  padding-top: 40px;
  @media (min-width: 768px) {
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
`

export const EverythingYouNeed = () => {
  return (
    <EverythingYouNeedContaner isBackgroundWhite data-loadable-component="everything-you-need">
      <WrapperPage>
        <Introduce />
      </WrapperPage>
    </EverythingYouNeedContaner>
  )
}
