import styled from "styled-components"

export const IntroduceRowStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 80px;

  &.auto-policy-updates {
    margin-bottom: 0px;
  }

  .col {
    width: 50%;

    &.text-content {
      display: flex;
      flex-direction: column;
      justify-items: center;
      max-width: 460px;
    }

    .title {
      max-width: 430px;
      font-size: 32px;
      font-weight: normal;
      line-height: 42px;
      text-align: left;
      color: #0f1722;
    }

    .intro-desc {
      margin-top: 25px;
      color: #676b87;
      font-size: 20px;
      letter-spacing: 0;
      line-height: 30px;
      max-width: 430px;
    }
    .example {
      display: flex;
      margin-top: 50px;
      color: #107af1;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
      text-decoration: none;

      .icon {
        margin-left: 10px;
      }
    }
  }

  &.reverse {
    flex-direction: row-reverse;
  }

  /* @media (min-width: 768px) {
    &.reverse {
      flex-direction: row-reverse;
    }
  } */

  @media (max-width: 767px) {
    flex-wrap: wrap;
    margin-bottom: 40px;
    flex-direction: column-reverse;
    &.reverse {
      flex-direction: column-reverse;
    }
    &.auto-policy-updates {
      margin-top: 0px;
      margin-bottom: 0px;
    }

    .col {
      width: 100%;
      padding: 0;

      &.text-content {
        margin: 40px auto 0px;
      }
      .title {
        text-align: center;
        font-size: 20px;
        line-height: 28px;
      }
      .example {
        justify-content: center;
      }
      .intro-desc {
        text-align: center;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 28px;
      }
    }
  }
`
export const IntroduceStyled = styled.div`
  @media (min-width: 768px) {
    .title {
      text-align: center;
    }
  }
`
