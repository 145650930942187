import React, { ReactElement } from "react"
import ArrowDown from "src/componentsV2/images/icons/arrow_down.svg"
import autoImportDesktopPNG from "src/images/home/auto_import_new.png"
import autoImportDesktopWEBP from "src/images/home/auto_import_new.webp"
import autoImport737PNG from "src/images/home/auto_import_737x562.png"
import autoImport737WEBP from "src/images/home/auto_import_737x562.webp"
import autoImport395PNG from "src/images/home/auto_import_395x301.png"
import autoImport395WEBP from "src/images/home/auto_import_395x301.webp"

import editContentDesktopPNG from "src/images/home_features/edit_content.png"
import editContentDesktopWEBP from "src/images/home_features/edit_content.webp"
import editContent737PNG from "src/images/home_features/edit_content_737x507.png"
import editContent737WEBP from "src/images/home_features/edit_content_737x507.webp"
import editContent395PNG from "src/images/home_features/edit_content_395x272.png"
import editContent395WEBP from "src/images/home_features/edit_content_395x272.webp"
import integrationDesktopPNG from "src/images/home_features/integration.png"
import integrationDesktopWEBP from "src/images/home_features/integration.webp"
import integration737PNG from "src/images/home_features/integration_737.png"
import integration737WEBP from "src/images/home_features/integration_737.webp"
import publishDesktopPNG from "src/images/home_features/publish_737x513.png"
import publishDesktopWEBP from "src/images/home_features/publish_737x513.webp"
import publishMobilePNG from "src/images/home_features/publish_395x275.png"
import publishMobileWEBP from "src/images/home_features/publish_395x275.webp"
import accessHandbookDesktopPNG from "src/images/home/access_handbook_new_722x705.png"
import accessHandbookDesktopWEBP from "src/images/home/access_handbook_new_722x705.webp"
import accessHandbookMobilePNG from "src/images/home/access_handbook_new_395x386.png"
import accessHandbookMobileWEBP from "src/images/home/access_handbook_new_395x386.webp"
import autoPolicyUpdatesDesktopPNG from "src/images/home/auto_policy_updates_desktop.png"
import autoPolicyUpdatesDesktopWEBP from "src/images/home/auto_policy_updates_desktop.webp"
import autoPolicyUpdatesMobilePNG from "src/images/home/auto_policy_updates_mobile.png"
import autoPolicyUpdatesMobileWEBP from "src/images/home/auto_policy_updates_mobile.webp"

import { Title } from "src/componentsV2/sections/Text"

import { IntroduceRowStyled, IntroduceStyled } from "./style"
import { Link } from "gatsby"

interface ExampleProps {
  text: string
  hashUrl: string
  icon: any
}

interface IntroduceProps {
  title: string
  desc?: ReactElement
  example?: ExampleProps
  imageDom: ReactElement
  name?: string
}

const data: Array<IntroduceProps> = [
  {
    title: "Auto-import your existing employee handbook",

    desc: <>Upload your existing content, or build your own, in
      AirMason’s employee handbook builder. Choose from dozens of templates <span className="text-black italic">(better yet, let our creative team design a custom template for your brand!)</span></>,
    imageDom: (
      <picture>
        <source srcSet={autoImportDesktopWEBP} type="image/webp" media="(min-width: 769px)" />
        <source srcSet={autoImportDesktopPNG} type="image/png" media="(min-width: 769px)" />
        <source srcSet={autoImport737WEBP} type="image/webp" media="(min-width: 426px)" />
        <source srcSet={autoImport737PNG} type="image/png" media="(min-width: 426px)" />
        <source srcSet={autoImport395WEBP} type="image/webp" />
        <source srcSet={autoImport395PNG} type="image/png" />
        <img src={autoImportDesktopPNG} alt='auto import' />
      </picture>
    ),
  },
  {
    title: "Build your employee handbook from scratch",
    desc: <>
      <p className="mb-4">
        AirMason’s easy-to-use employee handbook builder is the easiest way to modernize, design, and update your policies.
        Personalize it with images, GIFs, videos, and more to match your brand’s culture and aesthetic.
        </p>
      Invite your team to collaborate, with customized access and version control.
    </>
    ,
    imageDom: (
      <picture>
        <source srcSet={editContentDesktopWEBP} type="image/webp" media="(min-width: 769px)" />
        <source srcSet={editContentDesktopPNG} type="image/png" media="(min-width: 769px)" />
        <source srcSet={editContent737WEBP} type="image/webp" media="(min-width: 426px)" />
        <source srcSet={editContent737PNG} type="image/png" media="(min-width: 426px)" />
        <source srcSet={editContent395WEBP} type="image/webp" />
        <source srcSet={editContent395PNG} type="image/png" />
        <img src={editContentDesktopPNG} alt='edit design' />
      </picture>
    ),
  },
  {
    title: "Integrate your employees automatically",
    desc: <>Save time with our employee handbook builder’s HRIS partnerships or manually import them via CSV file. Customized access and version control allows you to restrict who can view your employee handbook.</>,
    example: {
      text: "Jump to integrations",
      hashUrl: "#integration",
      icon: ArrowDown,
    },
    imageDom: (
      <picture>
        <source srcSet={integrationDesktopWEBP} type="image/webp" media="(min-width: 769px)" />
        <source srcSet={integrationDesktopPNG} type="image/png" media="(min-width: 769px)" />
        <source srcSet={integration737WEBP} type="image/webp" />
        <source srcSet={integration737PNG} type="image/png" />
        <img src={integrationDesktopPNG} alt='employee integration' />
      </picture>
    ),

  },
  {
    title: "Publish your employee handbook!",
    desc: <>
      <p className="mb-4">Track electronic signatures, notify your employees of changes, and download activity reports.</p>
      You can even send customized email reminders to team members who need to sign off on updates to your employee handbook.    </>,
    imageDom:
      (
        <picture>
          <source srcSet={publishDesktopWEBP} type="image/webp" media="(min-width: 426px)" />
          <source srcSet={publishDesktopPNG} type="image/png" media="(min-width: 426px)" />
          <source srcSet={publishMobileWEBP} type="image/webp" />
          <source srcSet={publishMobilePNG} type="image/png" />
          <img src={publishDesktopPNG} alt='publish handbook' />
        </picture>
      )
  },
  {
    name: "access-handbook",
    title: "Employee Handbook Access On The Go",

    desc: <>
      Have your employees read, acknowledge, and sign off on policies anywhere, anytime, from any device!
    </>,
    imageDom:
      (
        <picture>
          <source srcSet={accessHandbookDesktopWEBP} type="image/webp" media="(min-width: 426px)" />
          <source srcSet={accessHandbookDesktopPNG} type="image/png" media="(min-width: 426px)" />
          <source srcSet={accessHandbookMobileWEBP} type="image/webp" />
          <source srcSet={accessHandbookMobilePNG} type="image/png" />
          <img src={accessHandbookDesktopPNG} alt='handbook accessability' />
        </picture>
      )
  },
  {
    name: "auto-policy-updates",
    title: "Automated Policy Updates",

    desc: <>
      Save your time and energy. AirMason’s AI driven, automated policy updates keep your employee handbook compliant and up-to-date.
      <div style={{width: "150px"}} className="mx-auto md:mx-0">
      <Link to={"/automated-policy-updates"} style={{textDecoration: "none"}}>
        <div className="bg-blue-darker text-white rounded-full py-4 px-8 font-bold" style={{marginTop: "32px", fontSize: "16px", lineHeight: 1}}>
        Learn More
        </div>
      </Link>
      </div>
    </>,
    imageDom:
      (
        <picture>
          <source srcSet={autoPolicyUpdatesDesktopWEBP} type="image/webp" media="(min-width: 426px)" />
          <source srcSet={autoPolicyUpdatesDesktopPNG} type="image/png" media="(min-width: 426px)" />
          <source srcSet={autoPolicyUpdatesMobileWEBP} type="image/webp" />
          <source srcSet={autoPolicyUpdatesMobilePNG} type="image/png" />
          <img src={autoPolicyUpdatesDesktopPNG} alt='automated policy updates' />
        </picture>
      )
  },
]

const IntroduceRow = ({
  data,
  className,
}: {
  data: IntroduceProps
  className: string
}) => {
  function scrollTo(e: any, hashUrl?: string): void {
    if (!hashUrl) {
      return
    }

    const element = document.getElementById(hashUrl.slice(1))
    const cookieConsent = document.querySelector(
      ".CookieConsent"
    ) as HTMLElement

    // Prevent cookie consent to convert the element
    if (element && cookieConsent) {
      e.preventDefault()
      window.scrollTo({ top: element.offsetTop - cookieConsent.offsetHeight })
    }
  }

  return (
    <IntroduceRowStyled className={`${className} ${data.name || ""}`}>
      <div className="col text-content">
        <h2 className="title">{data.title}</h2>
        {data.desc && <div className="intro-desc">{data.desc}</div>}
        {data.example && (
          <a
            className="example"
            onClick={(e: any) => scrollTo(e, data.example!.hashUrl)}
            href={data.example.hashUrl}
          >
            {data.example.text}
            <img
              className="icon"
              src={data.example.icon}
              width={10}
              height={20}
            />
          </a>
        )}
      </div>
      <div className="col col-image">
        {data.imageDom}
      </div>
    </IntroduceRowStyled>
  )
}

const Introduce = () => {
  return (
    <IntroduceStyled>
      <Title className="mx-auto max-w-92 mb-5 hidden md:block title">
        Everything you need to get started
      </Title>

      {data.map((item, idx) => (
        <IntroduceRow
          key={idx}
          data={item}
          className={idx % 2 === 1 ? "reverse" : ""}
        />
      ))}
    </IntroduceStyled>
  )
}

export default Introduce
